<template>
  <div data-app>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-select
              v-model="filter.nickname"
              :options="formattedProjectOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.ownerName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.projectName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.contractNumber"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.status"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end pb-3">
        <div>
          <b-button
            v-if="currentEditId"
            class="ml-auto mr-2"
            variant="success"
            @click="save"
          >
            <i class="flaticon2-check-mark"></i> Save
          </b-button>

          <b-button class="ml-auto" variant="success" @click="loadData">
            Load Design
          </b-button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-5">
          <div class="col-md-4 py-2 col-sm-12">
            <input
              type="file"
              class="d-none"
              ref="uploadExcel"
              @change="handleUploadExcel"
            />

            <b-dropdown right variant="success" class="ml-2">
              <template #button-content>
                <i class="fa fa-file-excel" /> Excel
              </template>

              <b-dropdown-item>
                <a class="d-inline text-dark" @click="uploadExcel">
                  <i class="flaticon-upload-1 mr-2"></i> Upload
                </a>
              </b-dropdown-item>

              <vue-excel-xlsx
                :data="excelData"
                :columns="exportExcelColumn"
                :file-name="
                  `${this.selectedNickname.code}-${this.selectedNickname.nickname}-monitoring design eksternal`
                "
                :sheetname="'sheet1'"
              >
                <b-dropdown-item>
                  <div class="d-inline">
                    <i class="flaticon-download mr-2"></i> Download
                  </div>
                </b-dropdown-item>
              </vue-excel-xlsx>
            </b-dropdown>
          </div>

          <div class="col-md-4 offset-md-4 py-2 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-search-1"></i>
                </span>
              </div>

              <input
                type="text"
                v-model="searchData"
                @change="this.$forceUpdate()"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <complete-table :loading="loading" :data="dataWithFilter">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 150px">Action</th>
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 250px">Title</th>
              <th style="min-width: 100px">Station</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 100px">Scope</th>
              <th style="min-width: 100px">Status</th>
              <th style="min-width: 100px">Revision</th>
              <th style="min-width: 100px">Version</th>
              <th style="min-width: 100px">Deadline</th>
              <th style="min-width: 150px">PIC Drafter</th>
              <th style="min-width: 150px">Delivery Date</th>
              <th style="min-width: 150px">Receiver</th>
              <th style="min-width: 250px">Transmittal Number</th>
              <th style="min-width: 250px">Revision Shd</th>
              <th style="min-width: 250px">Completed Date</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pr-0">
                <div class="d-flex">
                  <a
                    v-if="!isEdit(item.id)"
                    class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                    @click="editData(item)"
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    v-if="isEdit(item.id)"
                    class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                    @click="cancelEdit()"
                  >
                    <i class="flaticon2-cross"></i>
                  </a>

                  <a
                    class="btn btn-secondary font-weight-bolder font-size-sm mr-2"
                    @click="openHistoryModal(item)"
                  >
                    <i class="flaticon-list-2"></i>
                  </a>
                </div>
              </td>

              <td class="pl-0">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.station ? item.station.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type ? item.type.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope ? item.scope.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.last_status ? item.last_status.name : "-" }}
                </span>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.revision_version"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.revision_version }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.version"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.version }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.deadline"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          v-model="currentEditForm.deadline"
                          reset-button
                          reset-value=""
                          :no-flip="true"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ 
                        moment(item.deadline).isValid() ?
                        moment(item.deadline).format("DD-MM-YYYY") : ""}}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.drafter_pic"
                      :options="formattedPicOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.drafter_pic ? item.drafter_pic.name : "-" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.eksternal_distribution_date"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          v-model="currentEditForm.eksternal_distribution_date"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(item.eksternal_distribution_date).isValid() ?
                        moment(item.eksternal_distribution_date).format(
                          "DD-MM-YYYY"
                        ) : ""
                      }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.eksternal_receiver"
                      :options="formattedCetegoryOutstandingOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ optionalObject(item.eksternal_receiver, "name") }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.transmittal_number"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.transmittal_number }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.revision_shd"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.revision_shd }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.completed_date"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="currentEditForm.completed_date"
                        >
                        </b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(item.date_completed).isValid() ? 
                        moment(item.date_completed).format("DD-MM-YYYY") : ""}}
                    </span>
                  </template>
                </on-edit-row>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <v-dialog
      v-model="historyModal"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">History</v-card-title>

        <v-card-text>
          <complete-table :loading="historyLoading" :data="histories">
            <template #header>
              <tr class="text-left">
                <th></th>
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 300px">Title</th>
                <th style="min-width: 200px">Status</th>
                <th style="min-width: 100px">Revision</th>
                <th style="min-width: 100px">Version</th>
                <th style="min-width: 200px">Delivery Date</th>
                <th style="min-width: 100px">Delay</th>
                <th style="min-width: 200px">Storage Doc</th>
                <th style="min-width: 100px">Server</th>
                <th style="min-width: 100px">Asbuilt</th>
                <th style="min-width: 150px">Return Item</th>
                <th style="min-width: 150px">Outstanding</th>
                <th style="min-width: 150px">Receiver</th>
                <th style="min-width: 150px">Deadline</th>
                <th style="min-width: 150px">PIC Drafter</th>
                <th style="min-width: 150px">Transmittal Number</th>
                <th style="min-width: 150px">Revision SHD</th>
                <th style="min-width: 150px">Completed Date</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td>
                  <button
                    class="btn btn-default btn-xs"
                    @click="setExpandedIndexDetail(i)"
                  >
                    <span class="flaticon-eye"></span>
                  </button>
                </td>

                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ optionalObject(item.last_status, "code") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.eksternal_distribution_date).isValid() ?
                      moment(item.eksternal_distribution_date).format(
                        "DD-MM-YYYY"
                      ) : ""
                    }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.delay }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.doc_room }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.server }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.monitoring_asbuilt_status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding ? item.outstanding.name : null }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      item.eksternal_receiver
                        ? item.eksternal_receiver.name
                        : null
                    }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.deadline }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.drafter_pic ? item.drafter_pic.name : null }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.transmittal_number }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_shd }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.completed_date).isValid() ?
                      moment(item.completed_date).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>
              </tr>

              <tr
                v-bind:key="i + 'detail-modal'"
                v-if="expandedIndexDetail === i"
              >
                <td colspan="13">
                  <template>
                    <div>
                      <div class="card p-5 gutter-b">
                        <div class="card-body p-0">
                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Type : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.type, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                Station :
                              </span>

                              <span class="text-dark-75">
                                {{ optionalObject(item.station, "name") }}
                              </span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Approved : </span
                              ><span class="text-dark-75">{{
                                item.approved
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Planning Date : </span
                              ><span class="text-dark-75">{{
                                moment(item.planning_date).isValid() ?
                                moment(item.planning_date).format("DD-MM-YYYY") : ""
                              }}</span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Category : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.category, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.designer_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.drafter_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.checker_pic, "code")
                                }}</span
                              >
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Scope : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.scope, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer Hour : </span
                              ><span class="text-dark-75">
                                {{ item.designer_hour }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter Hour : </span
                              ><span class="text-dark-75">{{
                                item.drafter_hour
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker Hour : </span
                              ><span class="text-dark-75">{{
                                item.checker_hour
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    Monitoring Design Internal
                  </template>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="historyModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  arrayMoreThanOne,
  formattedOptions,
} from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import {
  GET_MONITORING_SHOP_DRAWING,
  UPDATE_MONITORING_SHOP_DRAWING,
} from "../../../core/services/store/monitor-shop-drawing.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { GET_PIC } from "../../../core/services/store/pic.module";
import OnEditRow from "../../content/widgets/advance-table/row/OnEditRow";
import { exportExcelColumn } from "./monitoringShopDrawingExternal";
import { excelToArray } from "@/core/helper/excel.helper";
import { showToast } from "../../../core/helper/toast.helper";
import ApiService from "@/core/services/api.service";
import { optionalData } from "@/core/helper/object-validation.helper";
import { GET_DIVISION } from "@/core/services/store/division.module";
import { GET_OUTSTANDING_CATEGORY } from "@/core/services/store/outstanding-category.module";

export default {
  name: "MonitoringShopDrawingExternal",
  components: { OnEditRow, CompleteTable },
  data() {
    return {
      expandedIndexDetail: null,
      histories: [],
      historyLoading: false,
      historyModal: false,
      searchData: "",
      Datas: [],
      exportExcelColumn: exportExcelColumn,
      filter: {
        id_project: null,
        nickname: null,
        station: null,
        scope: null,
        job_desk: "design",
        code: null,
      },
      currentEditId: null,
      currentEditForm: {
        deadline: null,
        drafter_pic: null,
        eksternal_distribution_date: null,
        eksternal_receiver: null,
        transmittal_number: null,
        completed_date: null,
        revision_shd: null,
        version: null,
        revision_version: null,
      },
      currentItem: null,
      selectedNickname: {
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        code: null,
      },
      options: {
        nicknames: [
          { value: null, text: "Choose Nickname" },
          { value: "1", text: "Makpa 1" },
          { value: "2", text: "Makpa 2" },
        ],
        stations: [
          { value: null, text: "Choose Stasiun" },
          { value: "1", text: "LRS0123123" },
          { value: "2", text: "LRS0000001" },
        ],
        scopes: [
          { value: null, text: "Choose Scope" },
          { value: "1", text: "HMI" },
          { value: "2", text: "CHIEF" },
        ],
        pics: [
          {
            value: 1,
            text: "Tan",
          },
          {
            value: 2,
            text: "Snow",
          },
          {
            value: 3,
            text: "Grow",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      monitoringShopDrawingExternal: (state) =>
        state.monitoringShopDrawing.data?.data ?? [],
      loading: (state) => state.monitoringShopDrawing.loading,
      pics: (state) => state.pic.pics?.data,
      divisions: (state) => state.division.divisions?.data ?? [],
      cetegoryOutstanding: (state) =>
        state.outstandingCategory.outstandingCategories?.data ?? [],
    }),

    dataWithFilter() {
      return this.Datas.filter((items) => {
        return (
          (items.numbering &&
            items.numbering
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.title &&
            items.title.toLowerCase().indexOf(this.searchData.toLowerCase()) >
              -1) ||
          (items.station &&
            items.station.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.type &&
            items.type.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.scope &&
            items.scope.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.last_status &&
            items.last_status.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.revision_version &&
            items.revision_version
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.date_dist_logistic &&
            items.date_dist_logistic
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.logistic_receiver &&
            items.logistic_receiver.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1)
        );
      });
    },

    formattedDivisionOptions() {
      return formattedOptions(this.divisions);
    },

    formattedCetegoryOutstandingOptions() {
      return formattedOptions(this.cetegoryOutstanding);
    },

    currentNickname() {
      return this.filter.nickname;
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Nickname" }, ...projects];
    },

    formattedPicOptions() {
      let pics = [];

      if (arrayMoreThanOne(this.pics)) {
        pics = this.pics.map((pic) => {
          return {
            value: pic.code,
            text: pic.name,
          };
        });
      }

      return [{ value: null, text: "Choose PIC" }, ...pics];
    },

    excelData() {
      return this.monitoringShopDrawingExternal?.map((shopDrawing) => {
        return {
          id: shopDrawing?.id,
          numbering: shopDrawing?.numbering,
          title: shopDrawing?.title,
          station: shopDrawing?.station?.code,
          type: shopDrawing?.type?.code,
          scope: shopDrawing?.scope?.code,
          last_status: shopDrawing?.last_status?.code,
          revision_version: shopDrawing?.revision_version,
          deadline: shopDrawing?.deadline,
          drafter_pic: shopDrawing?.drafter_pic?.code,
          eksternal_distribution_date: shopDrawing?.eksternal_distribution_date,
          eksternal_receiver: shopDrawing?.eksternal_receiver?.code,
          transmittal_number: shopDrawing?.transmittal_number,
          revision_shd: shopDrawing?.revision_shd,
          completed_date: shopDrawing?.completed_date,
          version: shopDrawing?.version,
        };
      });
    },
  },
  watch: {
    currentNickname(value) {
      if (!value) {
        Object.assign(this.selectedNickname, {
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          code: null,
        });

        return;
      }

      const project = this.projects.find(
        (project) => project.code === this.filter.nickname
      );

      Object.assign(this.selectedNickname, {
        ownerName: project?.owner,
        projectName: project?.name,
        status: project?.status,
        contractNumber: project?.contract_no,
        ...project,
      });
    },
  },
  methods: {
    optionalObject(object, key) {
      return optionalData(object, key);
    },

    setExpandedIndexDetail(index) {
      if (this.expandedIndexDetail === index) {
        this.expandedIndexDetail = null;
      } else {
        this.expandedIndexDetail = index;
      }
    },

    openHistoryModal(item) {
      this.historyLoading = true;

      ApiService.setHeader();

      ApiService.query(`project/dld/${item.id}/history/EKS/menu`)
        .then((response) => {
          this.histories = response.data.data;

          this.historyLoading = false;

          this.historyModal = true;
        })
        .catch(() => {
          this.historyModal = false;

          this.historyLoading = false;

          showToast("Info", "Nothing Change In Current Design", "info");
        });
    },

    toEditPage() {
      //
    },

    handleUploadExcel(event) {
      const file = event.target.files[0];
      console.log(this.postExcelData);
      excelToArray(file, this.postExcelData);
    },

    uploadExcel() {
      this.$refs.uploadExcel.click();
    },

    postExcelData(data) {
      if (this.monitoringShopDrawingExternal?.length > 0) {
        const cleanForm = data?.map((shopDrawing) => {
          const currentItem = this.monitoringShopDrawingExternal?.find(
            (findItem) => findItem?.id == shopDrawing[0]
          );

          const pic = this.formattedPicOptions.find(
            (pic) => pic.value === shopDrawing[9]
          );

          const receiver = this.formattedCetegoryOutstandingOptions.find(
            (extReceiver) => extReceiver.value === shopDrawing[11]
          );
          console.log(shopDrawing);
          return {
            id: shopDrawing[0] ? Number(shopDrawing[0]) : null,
            revision_version: shopDrawing[7] ?? null,
            deadline: shopDrawing[8] ?? null,
            eksternal_distribution_date: shopDrawing[10] ?? null,
            eksternal_receiver: {
              code: receiver?.value,
              name: receiver?.text,
            },
            transmittal_number: shopDrawing[12] ?? null,
            pic_drafter: {
              code: pic?.value ?? null,
              name: pic?.text ?? null,
            },
            date_completed: shopDrawing[14] ?? null,
            shop_drawing_pic: currentItem?.shop_drawing_pic ?? null,
            revision_shd: shopDrawing[13] ?? null,
            completed_date: shopDrawing[14] ?? null,
            version: shopDrawing[15] ?? null,
          };
        });
        const project = this.projects.find(
          (project) => project.code === this.filter.nickname
        );
        this.$store
          .dispatch(UPDATE_MONITORING_SHOP_DRAWING, { project, dld: cleanForm })
          .then(() => {
            this.cancelEdit();

            setTimeout(() => {
              this.loadData();
            }, 3000);
          });
      } else {
        showToast("Warning", "Feilad upload file, load data first", "warning");
      }
    },

    loadData() {
      if (!this.filter.nickname) {
        this.$bvToast.toast("Please Choose Project First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.$store
          .dispatch(GET_MONITORING_SHOP_DRAWING, {
            params: {
              ...this.filter,
              project_code: this.currentNickname,
            },
          })
          .then((x) => {
            console.log("DATA", x);
            this.Datas = x.data.map((y) => {
              if (y.last_status == null || y.last_status == undefined)
                y.last_status = {
                  code: "-",
                  name: "-",
                };

              if (
                y.logistic_receiver == null ||
                y.logistic_receiver == undefined
              )
                y.logistic_receiver = {
                  code: "-",
                  name: "-",
                };

              if (y.revision_version == null) {
                y.revision_version = "-";
              }
              if (y.date_dist_logistic == null || y.date_dist_logistic == "-") {
                y.date_dist_logistic = "-";
              }
              return y;
            });
          });
      }
    },

    save() {
      const pic = this.formattedPicOptions.find(
        (pic) => pic.value === this.currentEditForm.drafter_pic
      );

      const receiver = this.formattedCetegoryOutstandingOptions.find(
        (division) => division.value === this.currentEditForm.eksternal_receiver
      );

      const cleanForm = [
        {
          id: this.currentItem.id,
          ...this.currentEditForm,
          pic_drafter: {
            code: pic?.value,
            name: pic?.text,
          },
          eksternal_receiver: {
            code: receiver?.value,
            name: receiver?.text,
          },
          date_completed: this.currentEditForm.completed_date ?? null,
          shop_drawing_pic: this.currentItem.shop_drawing_pic,
          version: this.currentEditForm.version ?? null,
          revision_version: this.currentEditForm.revision_version ?? null,
        },
      ];
      const project = this.projects.find(
        (project) => project.code === this.filter.nickname
      );
      this.$store
        .dispatch(UPDATE_MONITORING_SHOP_DRAWING, { project, dld: cleanForm })
        .then(() => {
          this.loadData();
          this.cancelEdit();
        });
    },

    editData(item) {
      this.currentEditId = item.id;

      this.currentItem = item;

      this.currentEditForm = {
        deadline: item?.deadline,
        drafter_pic: item?.drafter_pic?.code,
        eksternal_distribution_date: item?.eksternal_distribution_date,
        eksternal_receiver: item?.eksternal_receiver?.code,
        transmittal_number: item?.transmittal_number,
        completed_date: item?.date_completed,
        revision_shd: item?.revision_shd,
        version: item?.version ?? null,
        revision_version: item?.revision_version ?? null,
      };
    },

    cancelEdit() {
      this.currentEditId = null;

      this.currentEditForm = {
        deadline: null,
        drafter_pic: null,
        eksternal_distribution_date: null,
        eksternal_receiver: null,
        transmittal_number: null,
      };

      this.currentItem = null;
    },

    isEdit(id) {
      return id === this.currentEditId;
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk,
      });
    },

    getPics() {
      if (!arrayMoreThanOne(this.pics)) {
        this.$store.dispatch(GET_PIC, {});
      }
    },

    getOutstandingCategory() {
      if (!arrayMoreThanOne(this.cetegoryOutstanding)) {
        this.$store.dispatch(GET_OUTSTANDING_CATEGORY, {});

        console.log("category", this.cetegoryOutstanding);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Monitor Design External" },
    ]);

    this.$store.dispatch(GET_DIVISION);

    this.getProjects();

    this.getPics();

    this.getOutstandingCategory();
  },
};
</script>

<style></style>

export const exportExcelColumn = [
  {
    label: "Id (unchagneable)",
    field: "id"
  },
  {
    label: "Numbering",
    field: "numbering"
  },
  {
    label: "Title",
    field: "title"
  },
  {
    label: "Station (code)",
    field: "station"
  },
  {
    label: "Type (code)",
    field: "type"
  },
  {
    label: "Scope (code)",
    field: "scope"
  },
  {
    label: "Last Status (code)",
    field: "last_status"
  },
  {
    label: "Revision Version",
    field: "revision_version"
  },
  {
    label: "Deadline (changeable)",
    field: "deadline"
  },
  {
    label: "Drafter PIC (code) (changeable)",
    field: "drafter_pic"
  },
  {
    label: "Date Receive (changeable)",
    field: "eksternal_distribution_date"
  },
  {
    label: "Receiver (changeable)",
    field: "eksternal_receiver"
  },
  {
    label: "Transmittal Number (changeable)",
    field: "transmittal_number"
  },
  {
    label: "Revision Shd (changeable)",
    field: "revision_shd"
  },
  {
    label: "Completed Date (changeable)",
    field: "completed_date"
  },
  {
    label: "Version (changeable)",
    field: "version"
  }
];
